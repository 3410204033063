.checkboxWrapper{
    width: 1040px;
    height: 400px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    margin: auto;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5px;
    border: 1px solid lightgrey;
    border-radius: 10px;
    padding: 15px;
    padding-left: 20px;
    box-shadow: inset 0 0 6px 0px lightgrey;
}
.searchBarWrapper{
    padding: 15px;
    box-sizing: border-box;
}
#searchBar{
    width: 100%;
    height: 30px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 5px 5px;
    font-size: 20px;
    box-sizing: border-box;
}
.mandantenCheckboxItem{
    width: 500px;
    margin: 5px;
}
.mandantenCheckboxItem > input{
    margin-right: 5px;
}
.buttonWrapper{
    margin: 20px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.currentScheduleWrapper{
    width: 100%;
    max-width: 1080px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.currentSchedule{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 5px;
    margin: 20px 0;
    background-color: seashell;
}
.schedule_inputWrapper{
    box-shadow: 1px 1px 5px 0 lightgrey;
    background:rgb(238, 238, 238);
    margin: 20px;
    padding: 10px;
    width: 700px;
    background-color: seashell
}
.scheduleInputWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.schedule_input{
    margin: 5px 0;
    padding: 5px;
    font-size: 18px;
}
.schedule_top{
    width: 100%;
    margin: 50px;
    margin-left: 20px;
}
.schedule_bottom{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}
.schedule_topleft{
    display: flex;
    justify-content: flex-start;
}
.schedule_node{
    padding: 4px;
    text-align: center;
    min-width: 150px;
    border-radius: 5px;
    margin: 5px 10px;
    box-sizing: border-box;
    background-color: white;
    font-size: 14px;
}
.nodeNameWrapper{
    width: 100%;
    height: 30px;
}
.nodeNameWrapper > span{
    font-size: 16px;
    font-weight: 700;
    margin-left: 20px;
}
#abschickenButtonWrapper{
    width: 100%;
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}