#headlineWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
#h1Wrapper{
    font-size: 24px;
    margin-right: 10px;
}
.breadcrumb{
    color: grey;
    font-weight: 400;
}
#spanWrapper{
    font-size: 10px;
    margin-top: -10px;
}
#spanWrapper > span:hover{
    color: rgb(255,135,36);
    cursor: help;
}
#infoModal{
    text-align: left;
    right: 50%;
    top: 50%;
    top: 100px;
    border-radius: 5px;
    z-index: 10;
}
#closeDivWrapper{
    display: flex;
    justify-content: flex-end;
    background-color: grey;;
}
#closeDiv{
    border: 1px solid black;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 22px;
    font-weight: 700;
}
#infoContentWrapper{
    padding-left: 10px;
    padding-right: 10px;
}
