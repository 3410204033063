#navSidebar{
    height: 100%;
    width: 80px;
    background: linear-gradient(rgb(251, 145, 6), rgb(255, 65, 7));
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
#sidebarElementWrapper{
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}
.sidebarElement{
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    text-align: center;
    color: white;
    border-radius: 30px 0px 0px 30px;
    cursor: pointer;
    user-select: none;
}
#sidebarElementSelected{
    background: white;
    color: rgb(255,135,36);
}