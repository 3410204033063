#adminComponent{
    width: 80%;
    min-width: 600px;
    min-height: 600px;
    justify-content: flex-start;
    align-items: flex-start;
}
#admin_buttonWrapper{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.admin_choiceButton{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    border: 5px solid rgb(255,135,36);
    background: white;
    width: 250px;
    height: 250px;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s;
}
.admin_choiceButton:hover {
    background: rgb(255,135,36);
    transition: all 0.3s;
}
.admin_choiceButton:hover *{
    color: white;
    transition: all 0.3s;
}
.admin_choiceButton > div{
    color: rgb(255,135,36);
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
}
.admin_choiceButton > div > i{
    font-size: 80px;
}
.admin_choiceButton > div > span{
    margin-top: 5px;
    font-size: 19px;
    font-weight: 700;
    text-align: center;
}