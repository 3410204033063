.userCircleWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30%;
}
.userCircle{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;    
    color: white;
    font-size: 25px;
    font-weight: 700;
}