.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  font-family: "Roboto", sans-serif
}
.App-header {
  background-color: white;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
