*,hmtl {
    margin:0;
    padding:0;
    text-align:left;
    font-family:sans-serif;
}
h4 {font-family:sans-serif;}
h3 {font-family:sans-serif;}
p, label {font-family:sans-serif;}
a {font-family:sans-serif; color:#d15423; text-decoration:none;}
button {
	margin:2px;
}
.hidden {
	display:none;
}
.noscroll {
	overflow:hidden;
}
.hasComment {
    background-color:#ffcb6d;
    border: 1px solid orange;
    transition: all 200ms;
}
.hasComment:hover{
    background-color: orange;
    color: white;
    transition: all 200ms;
}
/*datalist {
	display: none;
	position:relative;
}*/
#showCommentWrapper,
#showErrorWrapper,
#showSiteConfigWrapper,
#showErrorWrapper,
#showConfirmWrapper {
	width:100%;
	height:100%;
	position:fixed;
	z-index:10000;
	top:0px;
	background-color:rgba(0,0,0,0.7);
}
#mainApp{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 100px 1fr 250px;
    width: auto;
}
.fixedAnchor{
    top: 0;
    width: 250px;
}
#canvasMainMenu {
    position:relative;
    display:block;
    width:250px;
    height:100%;
    background: rgb(228, 234, 238);
}
#canvasWrapper{
    height: 100%;
}
#canvasStack {
    position: relative;
    width:1000px;
    height:10000px;
    position:relative;
    margin:0 auto;
    margin-top:30px;
}
#canvasMapStack {
    position: relative;;
    width:100px;
    height:auto;
}
#adtileInfo{
    background: rgb(228, 234, 238);
    padding: 5px;
    border-radius: 5px 0 0 5px;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    min-height: 200px;
}
#showIqadtileInformation p:nth-child(1){
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 5px;
    width: 100%;
    margin-left: 10px;
}
#showIqadtileInformation p{
    margin-bottom: 3px;
}
#canvasStack canvas {
    width:100%;
    height:100%;
    position:absolute;
    top:0px;
    left:0px;
    border:0px solid #ccc;
}
#canvasStack #canvasOne {
    /* z-index:100; */
}
#canvasMapStack #showIqadtileInformation {
    width:100px;
    height:auto;
    position:relative;
    top:0px;
    left:120px;
    color:black;
    background-color:lightgray;
    font-weight:bold;
    border:2px solid grey;
    /*margin-left:-200px;*/
    z-index:300;
    /*white-space:;*/
    /*overflow-y: scroll;*/
    padding-bottom:10px;
    /*opacity:.8;*/
}
#canvasMapStack #showIqadtileInformation p {
	margin-left:10px;
	margin-top:10px;
	line-height:1.5em;
}
#showIqadtileComment,
#showError,
#showSiteConfig,
#showConfirm {
    width:800px;
    height:auto;
    position:relative;
    margin:0 auto;
    top:200px;
    /*left:240px;*/
    color: black;
    background-color:white;
    font-weight:bold;
    border-radius: 5px;
    /*margin-left:-200px;*/
    /*z-index:10000;*/
    /*white-space:;*/
    /*overflow-y: scroll;*/
    padding-bottom:10px;
    opacity:.8;
    overflow-y:auto;
    max-height:600px;
}
#showSiteConfig {
	overflow-y:hidden;
}
#showSiteConfig textarea {
    width:800px;
    height:600px;
    background-color:transparent;
    color: black;
    border:0;
    wrap:soft;
}
#showError {
    background-color:red;
    border:3px solid purple;
    text-align:center;
}
#showIqadtileComment p,
#showError p,
#showConfirm p {
    margin-left:10px;
    margin-top:10px;
    line-height:1.5em;
    font-weight:normal;
    opacity:1;
}
#showError p,
#showConfirm p {
    font-size:1em;
}
#showConfirm,
#showConfirm p {
	text-align:center
}
#canvasMapStack #canvasMiniMapActiveArea,
#canvasMapStack #canvasMiniMap {
    position:absolute;
    top:0px;
    left:0px;
    width:auto;
    height:auto;
}
#canvasMapStack #canvasMiniMap {
    z-index:201;
}
#canvasMapStack #canvasMiniMapActiveArea {
    z-index:202;
    border:1px solid #000;
}
#mainApp h4 {
    text-align:center;
    color:#ccc;
}
#canvasMainMenu div label {
    display:block;
}
/* #canvasMainMenu div input{
    margin-top:10px;
    margin-left: 10px;
    width: 90%;
    height: 25px;
} */
button {
    cursor: pointer;
    width: 100px;
    height: 40px;
    text-align: center;
}
.hidden,
#mt1,
#mt2,
#mt3,
#mt4,
#mt5,
#mt6,
#mt7 {
	display:none;
}
#mt1 + #addPositionMenu,
#mt2 + #addIqadtileMenu,
#mt3 + #addSlotsizeMenu,
#mt4 + #addCommentMenu,
#mt5 + #configurationSummaryMenu,
#mt6 + #configurationSaveMenu,
#mt7 + #appliedRulesMenu {
    height:40px;
    overflow:hidden;
    transition: all 500ms;
}
#mt1 + #addPositionMenu > label,
#mt2 + #addIqadtileMenu > label,
#mt3 + #addSlotsizeMenu > label,
#mt4 + #addCommentMenu > label,
#mt5 + #configurationSummaryMenu > label,
#mt6 + #configurationSaveMenu > label,
#mt7 + #appliedRulesMenu > label {
    color:rgb(85, 83, 83);
    height: 40px;
    border: 1px solid lightgrey;
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
    align-items: center;
    user-select: none;
}
#mt1:checked + #addPositionMenu > label,
#mt2:checked + #addIqadtileMenu > label,
#mt3:checked + #addSlotsizeMenu > label,
#mt4:checked + #addCommentMenu > label,
#mt5:checked + #configurationSummaryMenu > label,
#mt6:checked + #configurationSaveMenu > label,
#mt7:checked + #appliedRulesMenu > label {
    color:rgb(85, 83, 83);
    border: 1px solid lightgrey;
}
#mt1:checked + #addPositionMenu,
#mt2:checked + #addIqadtileMenu,
#mt3:checked + #addSlotsizeMenu,
#mt4:checked + #addCommentMenu,
#mt5:checked + #configurationSummaryMenu,
#mt6:checked + #configurationSaveMenu,
#mt7:checked + #appliedRulesMenu {
    height:auto;
    overflow:visible;
    margin-bottom: 10px;
    transition: all 500ms;
}
#canvasMainMenu div label {
    /* margin-top:10px; */
    /* margin-left:-16px;
    margin-right:-16px; */
    /* width:204px; */
    /* padding:2px 10px 2px; */
    background-color:rgb(228, 234, 238);
    font-weight: bold;
    color:#333333;
    cursor: pointer;
}
#canvasMainMenu #addPositionMenu ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-position: inside;
    list-style-type: none;
    width: 95%;
    text-align: left;
}
#canvasMainMenu > div > div > ul,
#canvasMainMenu > div > div > div{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    width: auto;
}
#importTmsJson,
#toggleConfig{
    height: 20px !important;
    width: 90% !important;
}
#addPositionMenu > div > div,
#addSlotsizeMenu > div,
#addCommentMenu > div,
#addIqadtileMenu > div,
#configurationSummaryMenu > div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
}
#appliedRulesMenu > button,
#configurationSaveMenu > button,
#addSlotsizeMenu > div > button,
#addIqadtileMenu > div > button,
#addCommentMenu > div > button,
#configurationSummaryMenu > div > button{
    width: 110px;
    min-width: 100px;
    margin-bottom: 10px;
}
/* #addPositionMenu > div > div > button{
    width: 105px;
    height: 35px;
} */
.buttonAlignWrapper{
    width: 100%;
}
.categoryBreak{
    margin: 10px 0px;
}
#createCustom{
    width: 60px;
    height: 60px;
}
#editCustomPositionSize,
#customPositionSize{
    width: 135px;
    height: 25px;
    padding: 3px;
    font-size: 15px;
    align-self: center;
}
#canvasMainMenu h5{
    margin-left: 7px;
    margin-top: 5px;
    color: grey;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 2px;
}
#addComment,
#addOneSlotSize,
#addMultipleSlotSize,
#isSlotSizeThere,
#addIqAdtile{
    margin: 10px;
    width: 200px;
    height: 25px;
    padding: 3px;
    font-size: 15px;
    align-self: center;

}
#canvasMainMenu .plannerButton{
    color: white;
    width: 140px;
    height: 70px;
    border:1px solid white;
    border-radius: 5px;
    background: linear-gradient(0.15turn, rgb(255, 138, 91), rgb(255, 166, 163));
    box-shadow: 1px 1px 5px grey;
    transition: all 0.3s;
    user-select: none;
    margin: 10px;
}

#canvasMainMenu .plannerButton:hover{
    transition: all 0.3s;
}
#canvasMainMenu .saveButton:hover::after{
    content: "\A Speichern"; 
    font-size: 15px;
    white-space: pre;
}
#canvasMainMenu .throwButton:hover::after{
    content: "\A Änderungen \AVerwerfen"; 
    font-size: 15px;
    white-space: pre;
}
#canvasMainMenu .addSlotButton:hover::after{
    content: "\A Slot hinzufügen"; 
    font-size: 15px;
    white-space: pre;
}
#canvasMainMenu .editSlotButton:hover::after{
    content: "\A Slot bearbeiten"; 
    font-size: 15px;
    white-space: pre;
}
#canvasMainMenu .commentButton:hover::after{
    content: "\A Kommentare"; 
    font-size: 15px;
    white-space: pre;
}
#dialogWrapper{
    background: rgba(30,30,30,0.9);
    display: flex; 
    justify-content: center; 
    align-items: center; 
    position: fixed;
    z-index: 500;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
#folderPlusModal{
    display: grid;
    grid-template-rows: 50px 1fr;
    grid-template-columns: 1fr;
}
#dialogModal{
    background: white; 
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    padding: 10px 0;
}
.borderFolder{
    border-radius: 0px 5px 5px 5px;
}
.borderNoFolder{
    border-radius: 5px 5px 5px 5px;
}
#folderWrapper{
    grid-row: 1/2;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}
.modalFolder{
    background: lightgrey;
    color: grey;
    width: 70px;
    height: 30px;
    margin-right: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px 5px 0px 0px;
    font-weight: 700;
    cursor: pointer;
    user-select: none;
}
.inFocus{
    background: white;
    color: black;
}
#commentDialog{
    width: 100%;
    display:grid;
    grid-template-columns: 3fr 13fr 30px;
    height: 100%;
}
#commentSideBar{
    grid-column: 1/2;
    grid-row: 2/3;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    margin-top: 20px;
}
.sidebarItem{
    width: 80%;
    margin-bottom: 8px;
    padding: 5px;
    background: #eaeaea;
    border-radius: 5px;
}
.sidebarItem > h5{
    font-size: 17px;
    color: grey;
}
.sidebarItem.selected{
    transform: scale(1.05);
    background: rgb(255,135,36);
    color: white;
    transition: all 0.2s;
    /* box-shadow: 1px 1px 3px grey; */
}
.sidebarAdtile{
    transition: all 0.2s;
    cursor: pointer;
    user-select: none;
}
#topLevelComments{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    grid-column: 2/3;
    overflow-y: scroll;
    height: 100%;
}
.commentComponent{
    width: 90%;
    min-height: 100px;
    box-shadow: 0px 0px 5px rgb(158, 158, 158);
    border-radius: 5px;
    padding:10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
}
.comment_name{
    color: rgb(93, 93, 93);
    font-weight: 700;
    margin-bottom: 10px;
    width: 100%;    
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.comment_text{
    width: 100%;
}
.comment_date{
    font-size: 13px;
    font-weight: 700;
    color: rgb(112, 112, 112);
    margin-top: 10px;
}
#saveThrowDialog{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
}
.dialogButton{
    border: 1px solid white;
    border-radius: 5px;
    box-shadow: 1px 1px 3px grey;
    transition: all 0.3s;
}
.dialogSaveButton{
    background: linear-gradient(0.25turn, rgb(38, 195, 112), rgb(47, 195, 134));
}
.dialogThrowButton{
    background: linear-gradient(0.25turn, rgb(255, 128, 78),rgb(255, 109, 84));
}
.dialogSaveButton:hover{
    color: white;
    transition: all 0.3s;
}
.dialogThrowButton:hover{
    color: white;
    transition: all 0.3s;
}
.dialogCancelButton{
    color: rgb(182, 34, 46);
    border-radius: 5px;
    font-weight: 700;
    border: none;
    background: transparent;
    transition: all 0.3s;
}
.dialogCancelButton:hover{
    color: white;
    transition: all 0.3s;
    background: rgb(182, 34, 46);
}
#canvasMainMenu > div > div >#showIqadtileInformation{
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
}
.sizeTile{
    min-width:90px;
    min-height: 20px;
    background: linear-gradient(0.25turn, rgb(255, 255, 255), rgb(238, 250, 255));
    border-radius: 10px;
    margin: 3px 1px;
    text-align: center;
    padding: 2px 5px;
    line-height: 20px;
    border: 1px solid white;
}
.programmaticSize{
    background: linear-gradient(0.25turn, rgb(255, 255, 255), rgb(255, 238, 248));
}
.programmaticSize::after{
    font-size: 12px;
    content: " P";
    color: rgb(255, 51, 170);
}
#plannerButtonWrapper{
    display: flex;
    justify-content: center; 
    align-items: center;
    flex-wrap: wrap;
}
#addSlotDialog{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
#addSlotDialogGrid{
    width: 80%;
    height: 80%;
    display: grid;
    grid-template-rows: repeat(3, 1.5fr) 1fr 5fr;
    grid-template-columns: 1fr;
    gap: 5px;
    margin: 0 auto;
}
.inputFieldRow{
    display: grid;
    grid-template-columns: 120px 4fr;
    grid-template-rows: 1fr;
    font-size: 18px;
    border-radius: 5px;
    background: rgb(207, 207, 207);
    border: 1px solid rgb(158, 158, 158);
    border-radius: 5px;
    grid-column: 1/3;
}
.inputFieldRow.needsButton{
    grid-template-columns: 120px 4fr 1fr;
}
.addSlotLabel{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    color: rgb(97, 97, 97);
}
.addSlotInput{
    display: flex;
    justify-content: center;
    align-items: center;
}
.addSlotInput > input,
.addSlotInput > select{
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0 5px 5px 0;
    padding-left: 10px;
    font-size: 20px;
}
#slotSizeContainer{
    background: white;
    width: 100%;
    height: 100%;
    grid-row: 5/7;
    grid-column: 1/3;
    border: 1px solid rgb(158, 158, 158);
    border-radius: 10px;
    box-shadow: inset 0 0 4px rgb(192, 192, 192);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    overflow-y: auto;
}
.sizeChip{
    margin: 8px;
    background: rgb(200, 200, 200);
    color: rgb(58, 58, 58);
    font-size: 16px;
    padding: 3px;
    min-width: 100px;
    border-radius: 25px;
    display: grid;
    grid-template-columns: 5fr 1fr;
    grid-template-rows: 1fr;
    box-shadow: 1px 1px 4px rgb(107, 107, 107);
}
.sizeChip > span{
    text-align: center;
}
.sizeChip > i{
    font-size: 17px;
    cursor: pointer
}
#errorMsg{
    color: rgb(143, 1, 1);
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100%;
    background: rgba(255, 115, 115, 0.774);
    border: 1px solid rgb(143, 1, 1);
    border-radius: 5px;
}
#errorMsg  > span{
    line-height: 40px;
}
#addSlotSave{
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
#addCommentWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-row: 3/4;
    grid-column: 2/3;
    height: 100%;
}
#addCommentButton{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid rgb(255,135,36);
    background: white;
    color: rgb(255,135,36);
    transition: all 0.2s;
}
#addCommentButton:hover{
    background:rgb(255,135,36);
    transition: all 0.2s;
}
#addCommentButton:hover i{
    color: white;
    transition: all 0.2s;
}
#commentBoxActivated{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 8fr;
    grid-template-columns: 8fr 1fr;
}
#addCommentText{
    grid-row: 2/3;
    grid-column: 1/2;
    box-shadow: inset 0 0 4px rgb(192, 192, 192);
    border: 1px solid rgb(158, 158, 158);
    border-radius: 5;
    width: 100%;
    height: 100%;
    padding: 10px;
    margin: 0px;
    resize: none;
    border-radius: 5px;
    font-size: 15px;
    box-sizing: border-box;
}
#sendCommentButton{
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 0px;
    border-radius: 50%;
    border: 2px solid rgb(255,135,36);
    background: white;
    color: rgb(255,135,36);
    transition: all 0.2s;
}
#sendCommentButton:hover{
    background:rgb(255,135,36);
    transition: all 0.2s;
}
#sendCommentButton:hover i{
    color:white;
    transition: all 0.2s;
}
#minusCommentButton{
    width: 30px;
    height: 30px;
    text-align: center;
    color: grey;
    transition: all 0.2s;
    border: none;
    background: none;
}
#minusCommentButton:hover{
    transition: all 0.2s;
}
#minusCommentButton:hover i{
    color:rgb(180, 15, 29);
    transition: all 0.2s;
}