#adminUserWrapper{
    width: 70%;
    min-width: 600px;
    height: 700px;
    justify-content: flex-start;
    align-items: flex-start;
}
.card{
    min-width: 400px;
    width: 90%;
    min-height: 400px;
    height: 500px; 
    box-shadow: 1px 1px 4px rgb(161, 161, 161);
    border-radius: 25px;
    display: flex; 
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    overflow: hidden;
}
#adminUserGrid{
    width:100%;
    height:100%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 100px 1fr 20px;
}
#rTable{
    margin-left: 30px;
}
.tableCell{
    font-size: 14px;
    border-bottom: 1px solid rgb(197, 197, 197);
    cursor:pointer;
}
