.niceSelect{
    display: grid;
    grid-template-columns: 100px 1fr 100px;
    grid-template-rows: 1fr;
    width: 500px;
    height: 50px;
    border: 1px solid rgb(197, 197, 197);
    border-radius: 5px;
    overflow: hidden;
    box-sizing: border-box;
}
.isLoading{
    background-image: linear-gradient(to right,
        rgba(255, 255, 255, 0.9) 0%,
        rgba(247, 160, 0,0.6) 50%,
        rgba(255, 255, 255, 0.9) 100%);
    color: white;
    height: 100%;
    width: 100%;
    animation: 2s linear 0s infinite running move_eye;
}

@keyframes move_eye { from { margin-right: 100%; } to { margin-left: 100%; }  }

.labelWrapper{
    grid-column: 1/2;
    width: 100%;
    height: 100%;
    background-color: rgb(197, 197, 197);
    display: flex;
    justify-content: center;
    align-items: center;
}
.selectWrapper{
    grid-column: 2/3;
    width: 100%;
    height: 100%;
}
.labelWrapper > .label{
    color: rgb(70, 70, 70);
    font-size: 18px;
    text-align: center;
}
.selectWrapper > select{
    width: 100%;
    height: 100%;
    border: none;
    font-size: 18px;
}
#selectButton{
    margin: 0;
    width: 100%;
    height: 100%;
    min-width: initial;
    border: none;
    background: rgb(255,135,36);
    color: white;
    border-radius: 0;
    transition: all 200ms;
}
#selectButton:hover{
    transition: all 200ms;
    background: white;
    color: rgb(255,135,36);
}