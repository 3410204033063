#adminMandantenWrapper{
    width: 70%;
    min-width: 600px;
    height: 750px;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
}
.positioner{
    width: 90%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: 1fr;
}
#radioSwitch{
    display: flex;
    align-items: center;
    background: rgb(241, 241, 241);
    height: 30px;
    width: 70px;
    border-radius: 25px;
    box-shadow: inset 1px 1px 5px grey;
    cursor: pointer;
    user-select: none;
}
#radioMarker{
    width: 30px;
    height: 30px;
    background: linear-gradient(0.8turn,rgb(255, 126, 21),rgb(255, 197, 89));
    border-radius: 50px;
    transition: all 0.2s;
}
.switchLabel{
    margin: 10px;
    font-size: 20px;
    color: rgb(187, 187, 187)
}
.switchLabel.selected{
    color: rgb(255, 126, 21);
}
.ausgewählt{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
#adminMandantenWrapper .cardWrapper{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
#adminMandantenWrapper .card{
    margin-top: 20px;
    padding: 20px;
    width: 400px;
    height: 200px;
    min-width: 0;
    min-height: 0;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;
}
#adminMandantenWrapper .cardItem{
    width: 100%;
    text-align: left;
    font-size: 22px;
    letter-spacing: 2px;
}
#adminMandantenWrapper .cardItem:nth-child(2){
    margin-left: 40px;
}
#adminMandantenWrapper .cardItem:nth-child(3){
    margin-left: 80px;
}
.roundButton{
    background: white;
    border-radius: 20px;
    border: 1px solid rgb(255, 126, 21);
    color: rgb(255, 126, 21);
    width: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    padding: 0 10px;
    font-size: 15px;
    user-select: none;
}
.roundButton:hover{
    background: rgb(255, 126, 21);
    color: white;
}
.roundButton:hover span,
.roundButton:hover div{
    color: white;
}
.roundButton:focus{
    outline: none;
}
.roundButton.deactivated,
.roundButton.deactivated:hover{
    color: #f1f1f1;
    border: 1px solid #f1f1f1;
    background-color: #9e9e9e;
    cursor: not-allowed;
}
#mandantenBreadcrumbs{
    grid-row: 1/2;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}
#mandantenBreadcrumbs > .breadcrumb{
    width: 30%;
    height: 50px;
    border: 1px solid rgb(230, 230, 230);
    box-shadow: 1px 1px 6px rgb(190, 190, 190);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    word-wrap: break-word;
}
#mandantenBreadcrumbs > .breadcrumb:nth-child(1){
    border-top-left-radius: 17px;
    border-bottom-left-radius: 17px;
    width: 32%;
}
#mandantenBreadcrumbs > .breadcrumb:nth-last-child(1){
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
}
#newItemWrapper{
    grid-row: 2/3;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
}
#newItemCard{
    width: 480px;
    height: 290px;
    border-radius: 25px;
    box-shadow: 1px 1px 5px #b3b3b3;
    display: grid;
    grid-template-rows: repeat(5,1fr);
}
#newItemInput{
    width: 400px;
    height: 35px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #b3b3b3;
    padding: 3px;
    text-align: center;
    font-size: 19px;
}
#newItemSchemaSelect{
    width: 400px;
    height: 35px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #b3b3b3;
    padding: 3px;
    font-size: 19px;
    background: white;
}
#newItemCard button{
    width: 100px;
    border-radius: 25px;
    border: none;
    font-weight: 700;
}
#newItemButtonAccept{
    background: rgb(19, 199, 43);
    color: white;
}
#newItemButtonDecline{
    background: rgb(206, 16, 79);
    color: white;
}
.import_inputWrapper{
    width: 400px;
}
.niceSelect.import_input{
    width: 100%;
    margin: 20px 0;
    font-size: 25px;
    padding-left: 10px;
}