#loginWrapper{
    width: 350px;
    height: 450px;
}
#loginbox{
    width: 90%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}
#loginbox > div{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}
#loginbox > div > h2 {
    color: #282c34;
    margin-bottom: 20px;
}
.form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 25px;
}
.div > span{
    color: #282c34;
}
