#adminDatenWrapper{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 1200px;
    height: 700px;
}
#adminDatenComponent{
    width: 100%;
    height: 90%;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: 1fr;
}
#chooseDataList{
    grid-column: 1/2;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%;
    flex-wrap: wrap;
}
#chooseDataList > div{
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 22px;
    background: rgb(223, 223, 223);
    text-align: center;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s;
}
#chooseDataList > .activated{
    background: rgb(255,135,36);
    color: white;
    transition: all 0.2s;
}
#editMetricWrapper{
    width: 100%;
    height: 100%;
    grid-column: 2/3;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
#editMetricPositioner{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    flex-wrap: wrap;;
}
#editMetricInputWrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid grey;
    box-sizing: border-box;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 10px;
    font-size: 20px;
    padding-left: 5px
}
#editMetricInputWrapper > div > input,
#editMetricInputWrapper > input{
    height: 40px;
    box-sizing: border-box;
    border: none;
    margin: 0;
    font-size: 22px;
    padding: 5px;
    background: rgb(233, 233, 233);
}
#editMetricInputWrapper.slotsize > input{
    width: 70px;
    text-align: center;
}
#editMetricInputWrapper.iqadtiles > div > input{
    width: 80px;
    text-align: left;
}
#editMetricInputWrapper > div > button,
#editMetricInputWrapper > button{
    justify-self: flex-end;
    width: 30%;
    height: 50px;
    box-sizing: border-box;
    border: none;
    margin: 0;
    margin-right: -1px;
    background: rgb(255,135,36);
    font-size: 20px;
    color: white;
}
#editMetricContainer{
    width: 100%;
    height: 300px;
    border: 1px solid grey;
    border-radius: 5px;
    box-shadow: inset 1px 1px 3px rgb(189, 189, 189);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    padding: 5px;
    box-sizing: border-box;
    overflow-y: auto;
}
.hidden{
    visibility: hidden;
    transition: all 0.1s;
}
#saveMetricWrapper{
    grid-column: 3/4;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
#saveMetricPositioner{
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
}
#saveMetricPositioner > .button{
    margin-bottom: 10px;
    font-size: 18px;
}
#saveMetricPositioner > .button:nth-child(1):hover{
    background: rgb(206, 16, 79);
    border: none;
}
#saveMetricPositioner > .button:nth-child(2):hover{
    background: rgb(19, 199, 43);
    border: none;
}