#startWrapper{
    margin: 0 auto;
    margin-top: 100px;
    display: flex;
    justify-content: space-evenly;
    justify-items: center;
    flex-wrap: wrap;
    width: 90%;
}
.item{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 200px;
    width: 400px;
    border: 4px solid white;
    height: 400px;
    border-radius: 10px;
    color: white;
    font-weight: 700;
    font-size: 40px;
    transition: background-color 0.5s;
    background-color: transparent;
}
.item:hover{
    transition: all 0.5s;
    background-color: white;
    color: rgb(255,135,36)
}
.startWrapper > a {
    text-decoration: none;
}