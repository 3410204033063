#planungWrapper{
    min-width: 400px;
    height: 750px;
    margin: 0 auto;
    margin-top: 80px;
    width: 90%;
    background-color: white;
    display: block;
}
.divWrapper{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 auto;
    margin-top: 30px;
    box-sizing: border-box;
}
.inputDiv{
    display: flex;
    justify-content: center;
    align-items:center;
    flex-wrap: wrap;
    width: 250px;
}

.inputDiv > span{
    text-align: center;
    width: 100px;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}
.selectField{
    width: 250px;
    height: 500px;
    overflow-y: auto;
    border: 1px solid lightgrey;
    border-radius: 10px;
    transition: all 0.3s;
}
.selectField_deactivated{
    background-color: lightgrey;
    transition: all 0.3s;
}
.selectItem{
    padding-left: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: auto;
    height: 40px;
    font-size: 18px;
}
.selectItem:hover{
    cursor: pointer;
}
.selectedItem{
    background-color: rgb(255,135,36);
    color: white;
    transition: all 0.3s;
}