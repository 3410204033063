#userCard{
    grid-row: 2/3;
    grid-column: 2/3;
}
.userCardHr{
    width: 70%;
    margin: 0 auto;
    border-color: rgba(255, 255, 255, 0.65);
}
.cardItem{
    text-align: center;
    margin: 10px;
    font-size: 17px;
}
#cardItemName{
    font-size: 20px;
    font-weight: 700;
    margin: 20px 10px;
}
#cardItemButtons{
    margin-top: 30px;
}
#cardItemButtons > button{
    border-radius: 25px;
    border: none;
    font-weight: 700;
}
#cardItemButtonAccept{
    background: rgb(19, 199, 43);
    color: white;
}
#cardItemButtonDecline{
    background: rgb(206, 16, 79);
    color: white;
}
#permissionSelect{
    border: none;
    background: none;
    font-size: 16px;
}
#declineSpan{
    font-size: 15px;
    color: rgb(167, 20, 20);
    cursor: pointer;
}
.noPermissionError{
    color: red;
}