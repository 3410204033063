#adminImportComponent{
    width: 80%;
    min-width: 600px;
    min-height: 800px;
    justify-content: flex-start;
    align-items: flex-start;
}
#positioner{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
#adminImportCard{
    height: 600px;
}
.import_wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 85%;
}
.import_mandantItem{
    width: 300px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 1px 1px 4px 0px grey;
    font-size: 25px;
    font-weight: 700;
    color: rgb(58, 58, 58);
    cursor: pointer;
    transition: all 200ms;
    user-select: none;
}
.import_mandantItem:hover{
    background: rgb(255,135,36);
    transition: all 200ms;
    color: white;
}
.import_tableHeader{
    width: 100%;
    margin: 20px 0;
    background: lightgray;
    box-sizing: border-box;
    padding: 5px;
    padding-left: 20px;
    font-weight: 700;
}
.material-icons.arrowBack{
    font-size: 45px;
    cursor: pointer;
}
.backButtonWrapper{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.cardContent{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 70px 60px 60px 60px 60px 1fr;
}
.importWrapper > .niceSelect{
    width: 600px;
}
.niceSelectMandant{
    grid-row: 2/3;
    grid-column: 1/4;
    display: flex;
    justify-content: center;
    align-items: center;
}
.niceSelectEndgerät{
    grid-row: 3/4;
    grid-column: 1/4;
    display: flex;
    justify-content: center;
    align-items: center;
}
.niceSelectKnoten{
    grid-row: 4/5;
    grid-column: 1/4;
    display: flex;
    justify-content: center;
    align-items: center;
}
.connectButtonWrapper{
    grid-row: 4/5;
    grid-column: 1/4;
    display: flex;
    justify-content: center;
    align-items: center;
}
.connectButtonWrapper > button{
    width: 150px
}
.button> .material-icons{
    color: rgb(255,135,36);
}
.button:hover > .material-icons{
    color: white;
}
#noConnectionMessage{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    grid-row: 4/6;
    grid-column: 2/3;
    color: grey;
    font-size: 14px;
}
.connectionComponentWrapper{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.connectionComponent{
    width: 500px;
    height: 70px;
    display: grid;
    grid-template-columns: 1fr 80px 1fr;
    grid-template-rows: 1fr;
}
.sideNode{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(153, 153, 153);
    border-radius: 5px;
}
.sideNode.right{
    flex-wrap: wrap;
}
.sideNode.right > div{
    width: 100%;
    text-align: center;
}
.connector{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.connector > i {
    font-size: 35px;
}
.connectorLine{
    width: 35px;
    height: 2px;
    background: black;
}
.importWrapper{
    width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.row{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
#root.nodeItem{
    width: 400px;
}
.nodeItem{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 20px 0;
    padding: 5px 20px;
    font-size: 22px;
    border-radius: 5px;
    box-shadow: 0px 0px 3px 0px grey;
    box-sizing: border-box;
}
.nodeItem_name{
    width: 100%;
}
.nodeItem_children{
    width: 100%;
    box-sizing: border-box;
}
.nodeItem_check{
    margin: 0px 5px;
}