.whiteWrapper{
    height: auto;
    background-color: white;
    color: #282c34;
    height: 100%;
    min-height: 400px;
    margin: 0 auto;
    margin-top: 70px;
    margin-bottom: 70px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 5px;
    font-family: "Roboto", sans-serif;
    /* box-shadow: 2px 2px 7px 2px #121314; */
}
.button{
    margin: 0 10px;
    min-width: 120px;
    width: 40%;
    height: 50px;
    background: white;
    border:1px solid rgb(255,135,36);
    color: rgb(255,135,36);
    font-weight: 400;
    font-size: 14px;
    border-radius: 5px;
    font-family: "Roboto", sans-serif;
    transition: all 0.3s;
    user-select: none;
}
.button:hover{
    transition: all 0.3s;
    cursor: pointer;
    border:1px solid rgb(255,135,36);
    color: white;
    background: rgb(255,135,36);
}
.button_deactivated{
    background: grey;
    border:1px solid lightgrey;
    color: lightgrey;
    user-select: none;
}
.button_deactivated:hover{
    background: grey;
    border:1px solid grey;
    transition: all 0.3s;
    font-weight: 400;
    cursor: not-allowed;
}
.buttonHidden{
    display: none;
}
.input{
    border: none;
    border-bottom: 1px solid grey;
    background: transparent;
    min-width: 200px;
    width: 90%;
    height: 35px;
    margin-bottom: 5px;
    font-size: 16px;
}
.input:focus{
    outline: none;
    border-bottom: 2px solid rgb(255,135,36);
}
.inputDeactivated{
    background: lightgrey;
    color: grey;
    cursor: not-allowed;
}
.link{
    text-decoration: none;
}
.cssLoader{
    display: none;
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px double rgb(255,135,36); /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
}
.cssLoaderActivated{
    display: block; 
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.noSelect{
    user-select: none;
}
.div_100{
    width: 100%;
}