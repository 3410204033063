.neutralInputDiv{
    height: 500px;
    width:  400px;
    border: 1px solid #b3b3b3;
    border-radius: 10px;
    box-sizing: border-box;
    overflow-x: hidden;
}
.neutralInputDiv .listDiv{
    height: 100%;
}
.neutralInputDiv .listItem{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    height: 40px;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.2s;
    user-select: none;
}

.neutralInputDiv .listItem.selected{
    background: rgb(255,135,36);
    color: white;
    transition: all 0.2s;
}