#abfrageWrapper{
    height: auto;
    min-height: 700px;
    min-width: 800px;
    margin: 0 auto;
    margin-top: 80px;
    width: 85%;
    background-color: white;
    display: block;
}
#reportWrapper{
    margin: 0 auto;
    width: 900px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: wrap;
}
#reportItemWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.reportItem{
    border: 1px solid lightgrey;
    width: 900px;
    height: 80px;
    display: grid;
    grid-template-columns: repeat(2, 1fr) 15% 25%;
    text-align: left;
    border-radius: 5px;
    box-shadow: 1px 1px 4px 1px lightgrey;
    margin-bottom: 10px;
}
.reportItem_column{
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}
.reportItem_label{
    width: 100%;
    font-size: 10px;
    color: grey;
}
.reportItem_value{
    font-size: 20px;
}
.reportItem .actionButtons{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-weight: 700;
}
#newReport{
    display: block;
    text-decoration: none;
    line-height: 50px;
    width: 70px;
    margin: 0;
    margin-bottom: 50px;
}
.actionButtons > span{
    padding: 5px;
}
.delete{
    cursor: pointer;
}
.reportAction > a{
    transition: all 150ms;
    color:#282c34;
    cursor: pointer;
}
.reportAction > *, .reportAction > * >*{
    transition: all 150ms;
    color:#282c34
}
.reportAction.run:hover > *,.reportAction.run:hover > * >*{
    color: rgb(7, 138, 255);
    transition: all 150ms;
}
.reportAction.edit:hover > *,.reportAction.edit:hover > * > *{
    color: rgb(77, 209, 0);
    transition: all 150ms;
}
.reportAction.delete:hover ,.reportAction.delete:hover > *{
    color: rgb(209, 42, 0);
    transition: all 150ms;
}

#abfrageNeuWrapper{
    grid-column: 2/3;
    display: flex;
    justify-content: center;
    align-content: flex-start;;
    min-height: 500px;
    width: 500px;
    flex-wrap: wrap;
}
#abfrageNeuButtonWrapper{
    grid-column: 3/4;
    display: flex;
    justify-content: flex-end;
}

.dimWrapper {
    display: flex;
    justify-content:space-evenly;
    align-items: center;
    flex-wrap: nowrap;
    font-size: 20px;
    background-color: rgba(255, 201, 52, 0.6);
    box-shadow: 1px 1px 3px 1px lightgrey;
    border-radius: 5px;
    margin-top: 25px;
    padding: 5px 0px;
    width: 500px;
    transition: all 400ms;
}
.dimWrapper.tld{
    background: linear-gradient(0.25turn, rgb(255, 135, 36), rgb(255, 201, 52));

}
.dimWrapper > div{
    text-align: center;
    padding: 10px;
}
.dimLabelName{
    font-weight: 700;
    width: 200px;
}
.dimSelect{
    min-width: 80px;
}
.dimSelect > select{
    border: none;
    border-bottom: 1px solid grey;
    width: 100%;
    font-size: 20px;
    background: transparent;
}
.dimEdit > .material-icons{
    padding: 5px;
    cursor: pointer;
}
#inputButton{
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: auto;
    background: transparent;
}
#inputButton > input{
    background: transparent;
    border: none;
    border-bottom: 1px solid grey;
    font-size: 20px;
    margin-left: 0px;
}
#abfrageReportWrapper{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}
/* #headerMenuWrapper{
    width: auto;
} */
#headerMenuWrapper{
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-template-rows: 1fr;
    width: 90%;
    margin: 20px 0px;
}
.headerMenuElement{
    padding: 10px;
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: 1fr;
}
.headerMenuElement:nth-child(1){
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column: 1/2;
    border: 2px solid lightgrey;
    border-style: none double;
}
.headerMenuElement:nth-child(2){
    grid-column: 2/4;
    border: 2px solid lightgrey;
    border-style: none double none none;
}
.headerMenuElement:nth-child(3){
    grid-column: 4/5;
    border: 2px solid lightgrey;
    border-style: none double none none;
}
.headerMenuElement > h3{
    margin: 0px 0px 5px 0px;
    text-align: left;;
    font-size: 30px;
    font-weight: 400;
    color: grey;
}
#headerMenuReportname{
    font-weight: 700;
    font-size: 22px;
    color: rgb(48, 58, 104);
}
#filterWrapper{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.filterSwitch{
    display: block;
    background: rgb(238, 243, 245);
    margin: 5px;
    border-radius: 10px;
    min-width: 100px;
    font-size: 13px;
    box-shadow: 1px 1px 3px 1px lightgrey;
}
.filterElementHead{
    text-align: center;
    background-color: rgb(48, 58, 104);
    color: white;
    border-radius: 10px 10px 0px 0px;
}
.filterElementBody{
    text-align: center;
    height: 100px;
    overflow-y: auto;
    text-align: left;
}
.filterElementBody > ul{
    list-style: none;
    margin: 3px 0px;
    padding-left: 10px;
    padding-right: 10px;
}
.filterElement{
    display: flex;
    flex-wrap: nowrap;
    padding: 2px 10px 0px 2px;
}
.filterElement > input{
    width: 16px;
    height: 16px;
    margin-right: 4px;
}
.filterElementWrapper > li{
    cursor: pointer;
    /* background-color: white; */
    /* color: #282c34; */
    transition: all 200ms;
    padding: 3px 7px;
}
.checked{
    background-color: rgb(255,135,36);
    color: white;
    transition: all 200ms;
}
table{
    border-collapse: separate;
    border-spacing: 0 15px;
    width: 90%;
    margin: 0 auto;
}
th{
    color:grey;
    height: 50px;
    font-size: 18px;
    padding: 5px;
    width: 150px;
    text-align: left;
    font-weight: 400;
    text-align: center;
}
th:nth-child(3){
    width: 20%;
}
td{
    padding: 5px;
    min-width: 120px;
    font-size: 18px;
    text-align: left;
    word-wrap: normal;
    text-align: center;
}
/* tbody > tr:nth-child(odd){
    background: rgb(238, 243, 245);
} */
tbody > tr {
    height: 102px;
    transition: all 300ms;
}
tbody > tr:hover {
    background-color: rgb(241, 241, 241);
    transition: all 100ms;
}
tbody > tr > td {
    height: 100px;
        border-bottom: 1px solid lightgrey;
    }
.tag{
    position: relative;
    margin: 2px;
    text-align: center;
    border-radius: 10px;
    font-size: 16px;
}
.size{
    background-color: rgb(192, 232, 255);
    color: rgb(34, 144, 207);
    width: 48%;
    height: 21px;
}
.false{
    background-color: rgb(255, 192, 192);
    color: rgb(207, 34, 34);
    display: inline-block;
    width: 50px;
}
.true{
    background-color: rgb(192, 255, 197);
    color: rgb(27, 184, 92);
    display: inline-block;
    width: 50px;
}
.größen{
    height: 100px;
    overflow-y: auto;
}
#rowSelected{
    min-height: 130px;
    height: auto;
    transform: scaleX(1.05);
    background-color: white;
    transition: all 300ms;
    box-shadow: 0px 3px 4px 0px lightgrey;
}
#rowSelected  > td {
    min-height: 130px;
    font-size:  1.3em;
    font-weight: 600;
    border-bottom: 2px solid lightgrey;
}
#rowSelected .größen{
    height: auto;
}
.filterDropdown{
    width: 420px;
    height: 350px;
    background-color: white;
    box-shadow: 1px 1px 3px 1px lightgrey;
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: -10px;
}
.filterElementWrapper{
    list-style: none;
    text-align: left;
    margin: 0px;
    padding: 0px;
}
.filtered{
    color: rgb(7, 138, 255);
    transition: all 200ms;
}
.notSelected{
    color: #adadad;
    transition: all 200ms;
}
.material-icons{
    transition: all 200ms;
}