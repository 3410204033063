#navbarWrapper{
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 300px 1fr;
}
#logoLinkWrapper{
    grid-column: 2/3;
}
#navItemsWrapper{
    grid-column: 3/4;
    margin-right: 100px;
    display:flex;
    justify-content: flex-end;
    align-items: center;
}
.linkWrapper{
    margin-right: 30px;
}
#logoLink{
    font-weight: 400;
    font-size: 40px;
}
#logoLink:hover{
    color:black;
}
#logoLink:hover #orange_bold{
    color: rgb(255, 201, 52);
    transition: all ease-in 0.6s;
}
.navLink{
    text-decoration: none;
    color: black;
    font-size: 20px;
    transition: all 0.2s;
    cursor: pointer;
}
.navLink:hover{
    color: rgb(255,135,36);
    transition: all 0.2s;
}
#orange_bold{
    color: rgb(255,135,36);
    font-weight: 700;
    transition: all ease-in 0.3s;
}